<template>
  <div class="leaderboard-item" :class="[display]">
    <div class="profile-cont d-flex align-items-center justify-content-center"
         :class="{
           first : rank === '1st',
           second : rank === '2nd',
           third: rank === '3rd'
           }"
    >
      <b-img :src="item.profile_img_url || item.asset_url"
             v-bind="imageProps"
             rounded="circle"/>
    </div>
    <div class="detail-cont"
         :class="{
           first : rank === '1st',
           second : rank === '2nd',
           third: rank === '3rd'
           }"
    >
      <span class="user-name">
        {{ item.nickname }}
      </span>
      <span class="rank">
          {{ rank }}
      </span>
      <span class="exp-points"
            v-if="showExpPoints">
        {{ expPoints }}
      </span>
      <span class="level"
            v-if="showLevel">
        {{ level }}
      </span>
    </div>
  </div>
</template>
<style lang="scss">
  @import "../../../assets/scss/components/user/leaderboard/leaderboard-item";
</style>
<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    props : [
      'type',
      'display',
      'item',
    ],
    data() {
      return {
        imageProps : {
          blankColor : '#777',
          class      : 'prof-img',
        },
      };
    },
    computed : {
      rank() {
        return this.ordinalSuffixOf(this.item.id);
      },
      expPoints() {
        return this.item.exp_points + ' pt';
      },
      level() {
        return 'Lv. ' + this.item.level;
      },
      showExpPoints() {
        return (this.type === 'monthly' || this.type === 'weekly') &&
          [ 'md', 'lg', 'xl' ].indexOf(this.breakpoint) !== -1;
      },
      showLevel() {
        return this.type === 'all' &&
          [ 'md', 'lg', 'xl' ].indexOf(this.breakpoint) !== -1;
      },
    },
    methods : {

      /**
       * Ordinal Suffix
       * @param i
       * @return {string}
       */
      ordinalSuffixOf(i) {
        const j = i % 10,
              k = i % 100;
        if (j === 1 && k !== 11)
          return i + 'st';
        
        if (j === 2 && k !== 12)
          return i + 'nd';
        
        if (j === 3 && k !== 13)
          return i + 'rd';
        
        return i + 'th';
      },
    },
    mixins : [
      FilterMixin,
      BreakpointMixin,
    ],
  };

</script>